import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../developedcolourtest.png';
import { FaInstagram } from 'react-icons/fa';

function Footer() {
    const location = useLocation();
    const excludedPaths = ['/signin', '/signup', '/createaccount', '/passwordreset', '/dashboard'];
    
    if (excludedPaths.includes(location.pathname)) {
        return null;
    }

    return (
        <footer className="footer-wrapper">
            <div className="footer-content">
                <div className="footer-column">
                    <img src={logo} alt="Infield Logo" className="footer-logo" />
                    <p className="footer-slogan">Where fans learn, and athletes earn.</p>
                    <p className="footer-slogan">© 2024 Infield Tech, Inc.</p>
                    <a href="https://www.instagram.com/infield.app/" 
                       className="footer-social-link"
                       target="_blank"
                       rel="noopener noreferrer">
                        <FaInstagram /> @infield.app
                    </a>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">For Fans</h3>
                    <a href="/athletes" className="footer-link">Browse our roster</a>
                    <a href="/join" className="footer-link">Create a fan account</a>
                    <a href="/signin" className="footer-link">Sign in for existing users</a>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">For Athletes</h3>
                    <a href="/demo" className="footer-link">Watch a short demo</a>
                    <a href="/join" className="footer-link">Create an athlete account</a>
                    <a href="/signin" className="footer-link">Sign in for existing users</a>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">For Everyone</h3>
                    <a href="/" className="footer-link">Home</a>
                    <a href="/about" className="footer-link">Why Infield</a>
                    <a href="/contact" className="footer-link">Contact us</a>
                    <a href="/privacypolicy" className="footer-link">Privacy Policy</a>
                    <a href="/termsofservice" className="footer-link">Terms of Service</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer; 