import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo_icon from '../developedcolourtest.png';
import { FaInstagram } from 'react-icons/fa';

function TopNavbarAnonymous() {

    return (
      <div className='nav-wrapper'>
        <Navbar collapseOnSelect expand="sm" data-bs-theme="dark" className='nav-bar'>
          <Nav>
            <Nav.Link href="/">
              <img src={logo_icon} alt="Logo" className='nav-logo' />
            </Nav.Link>
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className='nav-hamburger'/>
          <Navbar.Collapse id="responsive-navbar-nav" className='navElements navbar-light'>
            <Nav className="navVertAlign">
              <Nav.Link href="/join" className='navElementLinks'>
                <div className='navElement'>
                  Join
                </div>
              </Nav.Link>
              <Nav.Link href="/signin" className='navElementLinks'>
                <div className='navElement'>
                  Sign In
                </div>
              </Nav.Link>
              <Nav.Link href="/about" className='navElementLinks'>
                <div className='navElement'>
                  About
                </div>
              </Nav.Link>
              <Nav.Link href="https://www.instagram.com/infield.app/" 
                       className='navElementLinks'
                       target="_blank"
                       rel="noopener noreferrer">
                <div className='navElement'>
                  <FaInstagram />
                </div>
              </Nav.Link>
              <Nav.Link href="/athletes" className='navElementLinks'>
                <Button className='navButton'>Ask an athlete</Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

    );
}

export default TopNavbarAnonymous;