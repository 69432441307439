import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

const RejectionModal = ({ 
  isOpen, 
  onConfirm, 
  onCancel, 
  initialNote = '' 
}) => {
  const [rejectionNote, setRejectionNote] = useState('');

  useEffect(() => {
    if (isOpen) {
      setRejectionNote(initialNote);
    }
  }, [isOpen, initialNote]);

  const handleConfirm = () => {
    onConfirm(rejectionNote);
  };

  if (!isOpen) return null;

  return (
    <div className='confirmation-modal-overlay'>
      <div className='confirmation-modal'>
        <div className='confirmation-modal-title'>
          Reject Submission
        </div>
        <div className='confirmation-modal-message'>
          Would you like to provide a reason for rejection? (Optional)
        </div>
        <textarea
          className='confirmation-modal-textarea'
          rows="4"
          placeholder="Enter rejection reason..."
          value={rejectionNote}
          onChange={(e) => setRejectionNote(e.target.value)}
        />
        <div className='confirmation-modal-button-container'>
          <Button onClick={handleConfirm} className='confirmation-modal-confirm'>
            Confirm Rejection
          </Button>
          <Button onClick={onCancel} className='confirmation-modal-cancel'>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RejectionModal;